import {types} from 'mobx-state-tree';
import EventModel from '../models/event-model';
import DeviceModel from '../models/device-model';
import PositionModel from '../models/position-model';
import config from '../config';

const EventStore = types
    .model('EventStore', {
        list: types.maybe(types.array(EventModel)),
        current: types.maybeNull(
            types.union(
                types.late(() => EventModel),
                types.reference(types.late(() => EventModel)),
            ),
        ),
        position: types.maybeNull(PositionModel),
        currentDevice: types.maybeNull(
            types.late(() => types.reference(DeviceModel)),
        ),
        positionBuffer: types.optional(types.array(PositionModel), []),
        enabledSound: types.optional(types.boolean, true),
    })
    .actions((self) => ({
        add: (event) =>
            (self.list = [...self.list.slice(0, config.eventLimit - 1), event]),
        addPositionInBuffer: (position) => {
            if (position.deviceId === self.currentDevice.id) {
                self.positionBuffer.unshift(position);
            }
        },
        removeAll: (_) => (self.list = []),
        remove: (id) => {
            const idx = self.list.findIndex((e) => e.id === id);
            self.list.splice(idx, 1);
        },
        setCurrent: (event) => (self.current = event),
        setCurrentDevice: (device) => (self.currentDevice = device),
        setBuffer: (positions) => (self.positionBuffer = positions),
        setPosition: (position) => (self.position = position),
        setEnableSound: (enable) => (self.enabledSound = enable),
    }))
    .views((self) => ({
        get toList() {
            return self.list.toJSON();
        },
        getEnabledSound() {
            return self.enabledSound;
        },

        findById: (id) => self.list.find((e) => e.id === id),
    }));

export default EventStore;
