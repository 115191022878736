const dev = {
  environment: 'dev',
  production: false,
  API_URL: 'https://server.monitorando.me/api',
  //  API_URL: 'https://server.staging.monitorando.me/api',
  //  API_URL: 'http://localhost:8082/api/',
  mapConfig: {
    street:
//      'https://{1-3}.traffic.maps.api.here.com/maptile/2.1/traffictile/newest/normal.day/{z}/{x}/{y}/512/png8?app_id=kw40mQz9OsBEkRlcURmr&app_code=K-zerLtDzSDv6nw3wQid4A',
        'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en&gl=en&key=SrFuw91J3ZnTFcKFV3unGVl7VDQ=',

      // 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?size=512&ppi=400&style=lite.day&apiKey=-uPKDNn0G_XFbAcqHg9fAkBrsBOSDj16KO794-Iwils',
    satellite:
      // 'https://{1-3}.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/jpg?app_id=kw40mQz9OsBEkRlcURmr&app_code=K-zerLtDzSDv6nw3wQid4A',
        // 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/jpeg?size=512&ppi=400&style=explore.satellite.day&apiKey=-uPKDNn0G_XFbAcqHg9fAkBrsBOSDj16KO794-Iwils',
        'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=en&gl=en&key=SrFuw91J3ZnTFcKFV3unGVl7VDQ=',
  },
  socketURL: 'wss://server.monitorando.me/api/socket',
  //  socketURL: 'wss://server.staging.monitorando.me/api/socket',
  // socketURL: 'ws://localhost:8082/api/socket',
  traceLimit: 5,
  eventLimit: 200,
  PATH: {
    device_icons: `${process.env.PUBLIC_URL}/images/device_icons`,
  },
  azulPay: {
    url: 'https://sandboxazulpay.pagtech.me/api/v1',
    base: 'https://sandboxazulpay.pagtech.me',
  },
};

const prod = {
  environment: window.location.hostname,
  production: true,
  API_URL: 'https://server.monitorando.me/api/',
  socketURL: 'wss://server.monitorando.me/api/socket',
  azulPay: {
    url: 'https://www.azulpay.online/api/v1',
    base: 'https://www.azulpay.online',
  },
};

const config = process.env.REACT_APP_ENV === 'prod' ? {...dev, ...prod} : dev;

export default {...config};
