import {flow, types} from 'mobx-state-tree';
import DeviceModel from '../models/device-model';
import {getDevices} from '../services/deviceService';

const DeviceStore = types
    .model('DeviceStore', {
        devices: types.optional(types.array(DeviceModel), []),
        selectedDevice: types.maybeNull(
            types.reference(types.late(() => DeviceModel)),
        ),
        updatedPositions: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        callDevices: flow(function* () {
            return yield getDevices();
        }),

        add: (device) => self.devices.push(device),

        addAll: (devices) => self.devices.push(...devices),

        setSelected: (id) => (self.selectedDevice = id),

        updateDevice: ({id, ...data}) => {
            const index = self.devices.findIndex((d) => d.id === id);
            if (index >= 0) self.devices[index].update(data);
        },

        removeDevice: (id) => {
            const index = self.devices.findIndex((d) => d.id === id);
            if (index >= 0) self.devices.splice(index, 1);
        },

        updatePosition: (position) => {
            if (position) {
                const index = self.devices.findIndex((d) => d.id === position.deviceId);
                if (index >= 0) {
                    self.devices[index].newPosition(position);
                    self.devices[index].setCurrentPosition(position);
                    // self.updatedPositions = true;
                }
            }
        },

        updatePositions: (positions) => {
            if (positions && positions.length) {
                for (const position of positions) {
                    const index = self.devices.findIndex((d) => d.id === position.deviceId);
                    if (index >= 0) {
                        self.devices[index].newPosition(position);
                        self.devices[index].setCurrentPosition(position);
                    }
                }
                self.updatedPositions = true;
            }
        },
    }))
    .views((self) => ({
        get pickNotSelected() {
            return self.devices.filter((d) => d !== self.selectedDevice);
        },

        get list() {
            return self.devices.toJSON();
        },

        findById: (id) => self.devices.find((e) => e.id === id),

        findPositionedDevices() {
            return self.devices.filter((device) => !!device.positionId && !!device.currentPosition);
        },

        findDevicesWithAlarm() {
            return this.findPositionedDevices().filter((device) => !!device.currentPosition.attributes.alarm);
        },
    }));

export default DeviceStore;
